.dash-qp-top-bar {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.dash-qp-top-bar-left {
  font-size: 23px;
}

.dash-qp-top-bar-right {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  color: #706E6E;
}

.dash-qp-questions {
  padding: 20px 0;
  background: rgba(196, 196, 196, 0.25);
}

.dash-qp-sq {
  display: flex;
  justify-content: space-evenly;
  margin: 0 20px 20px 20px;
  background: white;
  padding: 20px;
  border-radius: 15px;
}

.dash-qp-sq-left {
  color: #868181;
  font-size: 25px;
  margin-right: 20px;
}

.dash-qp-sq-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dash-qp-sq-center-bottom-bar {
  display: flex;
  gap: 40px;
  color: #868181;
}

.dash-qp-sq-right {
  display: flex;
  gap: 10px;

  svg {
    color: #868181;
    cursor: pointer;
  }
}

.qp-add-new-question-container {
  padding: 0 10px;
}

.qp-add-new-question-choices-container {
  .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .form-check-input {
      margin-top: 0;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}