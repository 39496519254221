.loadingWrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    font-size: 1.2em;
    color: #FFF;
    background: rgba(0, 0, 0, 0.1);
    z-index: 8000;
    transition: opacity 400ms ease-in,
}

.loadingLogo {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}

.loadingLogo img {
    width: 100px;
    animation: LogoZoomInZoomOut 1.2s infinite;
}

@keyframes LogoZoomInZoomOut {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.2, 1.2);
    }
    100% {
        transform: scale(1, 1);
    }
}
